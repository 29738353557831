
/* eslint-disable react/no-array-index-key */

import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { joinArray } from '../../utils/utils';

const ResumeKeySkills = () => {
	const { contentResumeJson: content } = useStaticQuery(graphql`
		query {
			contentResumeJson {
				skills {
					name
					skills
				}
			}
		}
	`);

	// console.log('ResumeKeySkills; content ', content);
	return (
		<section>
			<input type='checkbox' className='read-more-state' id='post-skills' />

			<a name='key-skills' />

			<h2>Key Skills</h2>

			<div className='read-more-wrap'>
				{content.skills.map((item, index) => (
					<div key={index} className={index !== 0 ? 'read-more-target' : ''}>
						<div className='skills'>
							<h4>{item.name}</h4>
							<p>{joinArray(item.skills)}</p>
						</div>
					</div>
				))}
			</div>

			<label htmlFor='post-skills' className='read-more-trigger' />
		</section>
	);
};

export default ResumeKeySkills;
