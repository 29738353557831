//

import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { MainLayout, SubpageHeader, createMetaData } from '../components';

import {
	ResumeSummary,
	ResumeExperience,
	ResumeProjects,
	ResumeKeySkills,
	ResumeEducation
} from '../components/resume';

const PageResume = () => {
	const { pageResumeJson: json } = useStaticQuery(graphql`
		query {
			pageResumeJson {
				meta_description
				meta_keywords
				meta_title
				title
				permalink
				description
			}
		}
	`);
	const { site, page, seo } = createMetaData(json);

	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<section id='resume-section'>
				<ResumeSummary />
				<ResumeExperience />
				<ResumeProjects />
				<ResumeKeySkills />
				<ResumeEducation />
			</section>
		</MainLayout>
	);
};

export default PageResume;
